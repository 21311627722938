import React from 'react'
import styles from './style.scss'

export function LoadingAnimate() {
  return (
    <div className={styles.content}>
      <div className={styles.loading}>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
