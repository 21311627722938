import { helpers } from '@common'
import { bridge } from '~/common/bridge'

const ENV = helpers.getRuntimeEnv()

export const getDeviceSDK = async () => {
  return await initDeviceSDK()
}

/** 获取原始的sdk实例，不用等待初始化 */
export const getDeviceSDKSync = async () => {
  const ALDeviceSDK = (await import('@al/device-data-sdk')).default
  return ALDeviceSDK
}

export const getDeviceId = async () => {
  if (bridge.isAkulaku) {
    const deviceId = await bridge.getDeviceIdV2()
    return deviceId
  } else {
    const ALDeviceSDK = await getDeviceSDKSync()
    const deviceId = await ALDeviceSDK.getDeviceId()
    return deviceId
  }
}

export const getDeviceUUID = async () => {
  const ALDeviceSDK = await getDeviceSDKSync()
  const deviceUUID = await ALDeviceSDK.getUuid()
  return deviceUUID
}

export const getDeviceData = async () => {
  if (bridge.isAkulaku) {
    return await bridge.getAllDeviceInfo()
  } else {
    const ALDeviceSDKInstance = await getDeviceSDK()
    return await ALDeviceSDKInstance.getDeviceData()
  }
}

export const getSmDeviceData = async () => {
  if (bridge.isAkulaku) {
    const data = await bridge.getAfiPackedDeviceData2()
    return JSON.stringify(data)
  } else {
    const ALDeviceSDK = await getDeviceSDK()
    const deviceData = await ALDeviceSDK.getAfiDataInfo()
    const smDeviceId = await ALDeviceSDK.getSmDeviceId()
    return JSON.stringify({
      web: {
        ...deviceData,
        sm_device_id: smDeviceId,
      },
    })
  }
}

// 确保init只初始化一次，当前版本初始化多次可能导致失败
let initPromise = undefined
// FIXME: 后续删除，外部通过getDeviceSDK来获取已经初始化号的 ALDeviceSDK
export let ALDeviceSDKInstance = undefined
export async function initDeviceSDK() {
  const ALDeviceSDK = (await import('@al/device-data-sdk')).default
  if (!initPromise) {
    initPromise = ALDeviceSDK.init({
      apiHost: `https://${ENV !== 'prod' ? 'test-' : ''}r.akulaku.net`,
      apiPath: '/web/encrypt_device/fingerprint',
      // reportDevelopEnv: ENV !== 'prod',
      reportAfterInited: false,
      reportThirdHost: true,
      extra: {
        scenario_number: 1999,
        app_brand: 'op',
        ext: {
          channel_id: helpers.URL.getParam('appId'),
          ref_no: helpers.URL.getParam('refNo'),
        },
      },
      smConfig: {
        organization: 'mkEM7Sm6QY93xu9gjRA0', //必填，组织标识
        publicKey:
          'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCpLqtHgUYC1Y+wkLETTEyp6MFYbVgnlqL3A6ePO3YHyOSIN3ndbrEKxRmowrV+7W6MGp0qlcuPF/HD9WOoTGklNqVceq5Y1pQOAWaGnuO/rxzY8r3/3VS0hfnNFK5yv7Vb8GiOXu0syPIwHpaU/eJkHsb4IipgT9H70K5+VKqxFQIDAQAB', //必填，私钥标识
        appId: 'io.silvrr.installment', //必填，应用标识，其他应用标识提前联系数美协助定义
        staticHost: 'webjs.akulaku.net', //必填, 设置 JS-SDK 文件域名
        apiHost:
          process.env.BUILD_ENV === 'local' ? location.host : 'r.akulaku.net', //私有化部署的服务域
        // void 0 让 SDK 自动获取
        protocol: process.env.BUILD_ENV === 'local' ? 'http' : void 0,
      },
    }).then(() => {
      ALDeviceSDKInstance = ALDeviceSDK
      return ALDeviceSDK
    })
  }
  return initPromise
}
