import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { helpers, akulaku } from '@common'

import styles from './style.m.scss'

const { get, run, value } = helpers
const className = helpers.classNames.react(styles)

export default class Mask extends Component {
  static propsTypes = {
    maskClosable: PropTypes.bool,
    close: PropTypes.func,
  }
  static defaultProps = {
    maskClosable: false,
    close: undefined,
  }

  render() {
    const { maskClosable, close } = this.props

    return (
      <div
        {...className('mask', this.props.className)}
        ref={(mask$) => {
          this.mask$ = mask$
        }}
        onClick={
          maskClosable
            ? (e) => {
                if (e.target === this.mask$) {
                  run(close)
                }
              }
            : undefined
        }
      >
        {this.props.children}
      </div>
    )
  }
}
