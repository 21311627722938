import ScrollView from './layout/ScrollView'

import * as Loading from './components/Loading'
import Button from './components/Button'
import Icon from './components/Icon'
import Img from './components/Img'

// import Basic, { withBasic } from '../../common @/Basic'
import popup, { PopupFrame } from './func/Popup'
// import Share from './func/Share'

export {
  ScrollView,
  Loading,
  Button,
  Icon,
  Img,
  // withBasic,
  // Basic,
  popup,
  PopupFrame,
  // Share
}

export default {
  ScrollView,

  Loading,
  Button,
  Icon,
  Img,

  // withBasic,
  // Basic,
  popup,
  PopupFrame,
  // Share
}
