import React, { Component } from 'react'

import { helpers } from '@common'

import styles from './style.m.scss'
import __default__img from './assets/img-default.png'
import __fail__img from './assets/img-fail.png'

const className = helpers.classNames.react(styles)
const { value } = helpers

const __real__img__host =
  window.location.host === 'mall.akulaku.com'
    ? '//akulaku.s3.amazonaws.com/'
    : '//com-silvrr-installment.s3.amazonaws.com/'

export default class Img extends Component {
  static defaultProps = {
    useDefault: false,
    autoAddHost: false,
  }

  render() {
    const {
      useDefault,
      autoAddHost: __useless__props__0,
      customDefaultImg: __useless__props__1,
      ...props
    } = this.props
    const { src, status, targetSrc, ...state } = this.state

    return (
      <img
        {...props}
        {...className(props.className, state.className)}
        {...{
          src,
          style: !useDefault && status === 'default' ? { opacity: 0 } : {},
          'data-target-src': targetSrc,
        }}
      />
    )
  }

  constructor(props) {
    super(props)

    const { useDefault, autoAddHost, customDefaultImg, fixedWidth } = props

    this.state = {
      src: useDefault ? value(customDefaultImg, __default__img) : '',
      targetSrc: `${autoAddHost ? __real__img__host : ''}${props.src}`,
      className: useDefault && !!!fixedWidth ? 'arui-img-default' : '',
      status: 'default',
    }
  }

  componentDidMount() {
    this.__auto__status()
  }

  shouldComponentUpdate(nextProps, nextState) {
    switch (this.state.status) {
      case 'fail':
      case 'done':
        if (this.props.src !== nextProps.src) {
          // 目标图片资源url改变时，重新渲染
          // return false阻止本次渲染，setTimeout下一次
          this.__rerender__timeout = setTimeout(this.__rerender)
          return false
        }
      case 'default':
        return this.state !== nextState
    }
  }

  componentWillUnmount() {
    clearTimeout(this.__rerender__timeout)
    const __empty__fn = () => {
      // nothing
    }
    this.__real__img.onload = this.__real__img.onerror = __empty__fn
  }

  __rerender__timeout
  __rerender = () => {
    const { useDefault } = this.props
    this.setState({
      src: useDefault ? __default__img : '',
      targetSrc: this.props.src,
      className: useDefault ? 'arui-img-default' : '',
      status: 'default',
    })
    this.__auto__status()
  }

  __real__img
  __auto__status = () => {
    // 创建目标图片标签
    const __real__img = document.createElement('img')
    let { targetSrc: __real__src } = this.state
    // 无src，状态标记为失败，加载失败图片
    if (!__real__src) {
      return this.setState({
        src: __fail__img,
        className: 'arui-img-fail',
        status: 'fail',
      })
    }

    let times = 2
    // 设置src属性，开始加载目标图片资源
    __real__img.src = __real__src
    // 目标图片加载成功回调
    __real__img.onload = () => {
      this.setState({
        src: __real__src,
        className: '',
        status: 'done',
      })
    }
    // 目标图片加载失败回调
    __real__img.onerror = () => {
      times--
      // 替换资源url的host部分，尝试再次加载目标资源
      if (times > 0) {
        __real__src = __real__src.replace(
          /\/\/[\w,.,:]{1,}\//,
          __real__img__host,
        )
        __real__img.src = __real__src
      } else {
        // 第二次尝试加载失败，状态标记为失败
        this.setState({
          src: __fail__img,
          className: 'arui-img-fail',
          status: 'fail',
        })
      }
    }

    this.__real__img = __real__img
  }
}
