// @ts-expect-error: missing type declaration
import BridgeV2 from '@akulaku/web-bridge'
import { isAndroid } from '@common/helpers'
import _ from 'lodash'
import { urlQuery } from '~/common/urlQuery'
let iframe: HTMLIFrameElement
const log = (...args: any[]) => console.log(`[bridge]: `, ...args)

/**
 * aku app 的桥接
 * 跳转页面映射关系表
 * https://docs.google.com/spreadsheets/d/1o5NYHEwiaAO4f5sVxU2aVAZswxvFGiW3HcFen9BUWh0/edit#gid=293354987
 */
export const bridge = {
  /**
   * 是否在app内
   */
  isAkulaku: (() => {
    // FIXME: 现开发中，先强制为在app内打开，开完成后去除判断
    // if (process.env.NODE_ENV === 'development') {
    //   return true
    // }
    return BridgeV2.isAkulaku()
  })(),

  /**
   * 跳转拿到app授信界面
   */
  jumpCreditPage(query?: AnyRecord) {
    BridgeV2.jump({
      url: `ak://m.akulaku.com/1701?${urlQuery.stringify(query)}`,
    })
  },
  /**
   * 跳转拿到app 首页 paylater tab
   */
  jumpPaylaterTab() {
    BridgeV2.jump({
      url: `ak://m.akulaku.com/5?tab=1`,
    })
  },
  /**
   * 获取 app 版本号
   * @see https://test-ec-mall.akulaku.com/jsbridge/#/detail/getAppVersionV2
   */
  getAppVersionV2(): Promise<number> {
    return new Promise((resolve) => {
      log(`getAppVersionV2，开启获取app版本号`)
      try {
        BridgeV2.getAppVersionV2('', (res: any) => {
          log(`getAppVersionV2 响应:`, res)
          let versionCode = _.get(res, 'data.versionCode')
          if (BridgeV2.isAkulakuIOS()) {
            // example: 4.5.5
            const appVersion = _.get(res, 'data.appVersion', '')
            // ios 没有返回versionCode字段，需要自己处理
            //  input : 4.5.5
            //  output: 455
            versionCode = appVersion.split('.').join('')
          }
          versionCode = +versionCode
          if (Number.isNaN(versionCode)) {
            versionCode = 0
          }
          log(`getAppVersionV2 格式化后的versionCode:`, versionCode)
          resolve(versionCode)
        })
      } catch (error) {
        log(`getAppVersionV2 异常:`, error)
        resolve(0)
      }
    })
  },

  /**
   * 判断当前是否是最小支持Alipay+的版本
   * 当前最小支持版本为 4620
   */
  async isSupportAlipayPlusVersion() {
    log(`isSupportAlipayPlusVersion，开始判断是否支持Alipay+版本`)
    const version = await bridge.getAppVersionV2()
    const isSupport = version >= 4620
    log(
      `isSupportAlipayPlusVersion，结束判断是否支持Alipay+版本，结果:`,
      isSupport,
    )
    return isSupport
  },

  /**
   * 外部webview通过deeplink打开app
   * 严格来讲这不属于bridge，但是为了方便，放在这里
   * 允许不提供actionKey，不提供的话默认为app当前页面
   */
  openApp(query?: Record<string, string>) {
    // ios 4.6.20 的版本以前 只支持actionkey放在第一个参数
    // 这里为了兼容低版本ios 统一把第一个参数改为actionKey
    let appQueryStr = ''
    if (query) {
      const { actionKey, ...other } = query
      appQueryStr = urlQuery.stringify(
        { actionKey, ...other },
        { addQueryPrefix: true },
      )
    }

    let url: string
    if (isAndroid()) {
      url = `silvrr://silvrr.akulaku.com${appQueryStr}`
    } else {
      url = `akulaku://akulaku${appQueryStr}`
    }
    console.log('[openApp]: url', url)
    // location.href = url
    // 在京东app的场景下，未下载app将导致跳转到了打开失败的页面，这里使用iframe来解决
    // 如果希望 URL Scheme 出错时，唤端不会跳转到错误页面，那么可以使用 iframe 的形式，不过需要注意个别机型的兼容性
    if (!iframe) {
      iframe = document.createElement('iframe')
      iframe.style.cssText = 'display:none;border:0;width:0;height:0;'
      document.body.append(iframe)
    }
    iframe.src = url
  },

  /**
   * 下载app
   */
  downloadApp() {
    const config = {
      android:
        'https://play.google.com/store/apps/details?id=io.silvrr.installment',
      ios: 'https://itunes.apple.com/app/id1125683586',
    }
    if (bridge.isAkulaku) {
      // 在app内的话，安卓需要用这个才能跳转到应用商店
      config.android = `market://details?id=io.silvrr.installment`
    }

    const url = isAndroid() ? config.android : config.ios
    log(`downloadApp，开始下载app，url:`, url)
    location.href = url
  },

  /**
   * 功能说明：吊起系统浏览器,打开一个 url
   * @see https://test-ec-mall.akulaku.com/jsbridge/#/detail/openBrowser
   */
  openBrowser(url: string) {
    log('openBrowser，url:', url)
    if (!url) {
      log('openBrowser，url为空')
      return
    }
    BridgeV2.openBrowser({ url })
  },
  handleBackBehaviorV2: BridgeV2.handleBackBehaviorV2,
  /** 获取设备指纹 */
  getDeviceIdV2: () => {
    return new Promise((resolve) => {
      BridgeV2.getDeviceIdV2(null, (data: any) => {
        resolve(data?.success ? data.data.deviceId : 'unknown')
      })
    })
  },
  /** 获取设备uuid */
  getDeviceUUID: () => {
    return new Promise((resolve) => {
      BridgeV2.getDeviceUUID(null, (data: any) => {
        resolve(data?.success ? data.data.deviceUUID : 'unknown')
      })
    })
  },
  /** 获取完整的设备数据 */
  getAllDeviceInfo: () => {
    return new Promise((resolve) => {
      BridgeV2.getAllDeviceInfo(
        {
          scene: 1014,
        },
        (data: any) => {
          resolve(data?.success ? data.data : {})
        },
      )
    })
  },
  /** 获取数美设备数据 */
  getAfiPackedDeviceData2: () => {
    return new Promise((resolve) => {
      BridgeV2.getAfiPackedDeviceData2(
        {
          scene: 1014,
          refer_id: '',
        },
        (data: any) => {
          resolve(data?.data?.eventReportDataInEuFormat || {})
        },
      )
    })
  },
}
