import React from 'react'
import { helpers } from '@common'
import styles from './style.m.scss'

const className = helpers.classNames.react(styles)
const { value } = helpers

const Button = ({
  children,
  type = 'default',
  disable = false,
  active = false,
  onClick,
  ...props
}) => (
  <div
    {...props}
    onClick={!disable ? onClick : null}
    {...className(type, props.className, { active, disable })}
  >
    {children}
  </div>
)

export default Button
