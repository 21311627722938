import React from 'react'
import styles from './style.m.scss'
import classNames from 'classnames'

export default function ScrollView(props) {
  return (
    <div className={classNames(styles.scrollView, props.className)}>
      {props.children}
    </div>
  )
}
