/* eslint-disable no-control-regex */
import { URL, storage } from '@common/helpers'
import _ from 'lodash'
/**
 * 安全组件需要的头信息
 *  X_MERCHANT_ID X_REF_NO X_SIGN字段（分别对应 appId, refNo, sign
 * */
export function getExtraHeaders() {
  const { appId, refNo, sign } = URL.allParam()
  return {
    'X-MERCHANT-ID': appId,
    'X-REF-NO': refNo,
    'X-SIGN': _.replace(sign, /[^\x00-\x7F]/g, ''),
  }
}

/**
 * 获取安全组件通用参数
 * @returns Object
 */
export function getCommonParams() {
  const { appId, refNo, sign } = URL.allParam()
  return {
    appId,
    refNo,
    sign: _.replace(sign, /[^\x00-\x7F]/g, ''),
  }
}

const RISK_CACHE_KEY = 'RISK_CPN_CACHE_KEY'

/** 保存安全组件相关的临时参数或数据 */
export function setSecurityCacheData(params) {
  storage.setSession(
    RISK_CACHE_KEY,
    JSON.stringify({
      ...getSecurityCacheData(),
      ...params,
    }),
  )
}

/**
 * 获取安全组件相关的临时参数或数据
 */
export function getSecurityCacheData() {
  const data = storage.getSession(RISK_CACHE_KEY)
  return data ? JSON.parse(data) : {}
}
